import React, {useEffect, useRef, useState} from 'react'
import Image from "next/image";
import zeniq from '../../public/assets/zeniq.png'
import similarity from "string-similarity-algorithm";
import stringSimilarity from "string-similarity";
import io from "socket.io-client";
import QRCode from "react-qr-code";
import help from '../../public/assets/help.svg'
import { isMobile } from 'react-device-detect'
import cross from '../../public/assets/cross-svgrepo-com.svg'

const banned_token_names = [
    'ZENIQ',
    '🐟',
    '👏👏👏',
    '👏',
    'TUPAN',
    'RAMZIQ',
    'SAFIR',
    'AVINOC',
    'Solaiman',
    'Dokter',
    'Ofner',
    'Avelar',
    'Winter',
    'Oderli',
    'VOO',
    'Vougee'
]

const banned_symbol_names = [
    'ZENIQ',
    '🐟',
    '👏👏👏',
    '👏',
    'TUPAN',
    'RAMZIQ',
    'SAFIR',
    'AVINOC',
    'Solaiman',
    'Dokter',
    'Ofner',
    'Avelar',
    'Winter',
    'Oderli',
    'VOO',
    'Vougee'
]

const ERROR_NAME = {
    BANNED: 'Please choose another name!',
    LENGTH: 'The name has to be between 3 and 20 symbols!',
}
const ERROR_SYMBOL = {
    BANNED: 'Please choose another symbol!',
    LENGTH: 'The symbol has to be between 3 and 6 symbols!'
}
const ERROR_AMOUNT = {
    INVALID: 'Please enter a valid number!',
}

const Form = () => {

    const name = useRef()
    const symbol = useRef()
    const amount = useRef()
    const [mintable, setMintable] = useState(true)
    const [validForm, setValidForm] = useState(false)
    const [socket, setSocket] = useState(null);
    const [error, setError] = useState()
    const [progress, setProgress] = useState('form')
    const [qrlink, setQRLink] = useState('testing');
    const [contractAddress, setContractAddress] = useState('ERROR');
    const form = useRef({})
    const coin = useRef()
    const stamp = useRef()
    const [showTooltip, setShowTooltip] = useState(false)
    const [banner, setBanner] = useState(true)


    // const backend = 'yes'
    const backend = 'https://tokens.zeniq.net'

    useEffect(() => {
        if (!socket) {
            const newSocket = io(backend);
            setSocket(newSocket);
        }

        return () => {
            socket &&
            socket.disconnect();
        };
    }, []);

    useEffect(() => {
        if (socket) {
            socket.on("connect", () => {
                console.log("connected");
            });

            socket.on("disconnect", () => {
                console.log("disconnected");
            });

            socket.on("qr_code", data => {
                console.log('qr_code')
                setQRLink(data)
                setProgress('coin')
            });


            socket.on("execute_success", data => {
                console.log('execute_success')
                setContractAddress(data?.token_address || 'ERROR')
                setProgress('token')
                setTimeout(() => {
                    const sound = document.getElementById('coin-sound');
                    sound.play()
                }, 900)
            });

        }
    }, [socket]);

    useEffect(() => {
        if(validForm) {
            console.log('generate_qr_code')
            socket.emit("generate_qr_code", JSON.stringify(form.current));
            // setTimeout(() => {
            //     setQRLink(qrlink || 'yes') // TODO DEV
            //     setQRLink(qrlink)
            //     setProgress('coin')
            // }, 1100)
        }
    }, [validForm])

    useEffect(() => {
        setBanner(true)
        if(progress === 'token') {
            coin.current?.play()
        } else if (progress === 'coin'){
            setTimeout(() => {
                stamp.current?.play()
            }, 900)
        }
    }, [progress])

    return (
        <>
            {
                progress === 'form' ?
                    <div id="form" className={'form ' + (error ? 'form-error ' : 'form-no-error ') + (validForm ? 'coin-step' : '')}>
                        <div className="header">Tokengenerator</div>
                        <div className='input-container'>
                            <div className="input name">
                                <div className="label">Name</div>
                                <input ref={name} type="text" autoComplete="off" autoCorrect="off"/>
                            </div>
                            <div className="input symbol">
                                <div className="label">Symbol</div>
                                <input ref={symbol} type="text" autoComplete="off" autoCorrect="off" style={{textTransform: "uppercase"}} />
                            </div>
                            <div className="input amount">
                                <div className="label">Amount</div>
                                <input ref={amount} inputMode="numeric"
                                       autoComplete="off" autoCorrect="off" type="text" pattern="^[0-9]*[.,]?[0-9]*$"
                                       placeholder="0"
                                       minLength="1" maxLength="20" spellCheck="false"/>
                            </div>
                            <div className={"input mintable-container " + mintable} onClick={() => {
                                setMintable(!mintable)
                            }}>
                                <div className="label radio">Mintable</div>
                                <div className={"toggle-switch mintable-" + mintable}>
                                    <div className="toggle"/>
                                    <div className="checkmark"/>
                                    <div className="cross"/>
                                </div>
                            </div>
                        </div>
                        {/*{error ? <div className='error'>{error}</div> : <div className='placeholder'>Yes</div>}*/}
                        <div className="submit-button" onClick={() => {
                            // setError(false)
                            const form_data = {
                                name: name.current?.value || '',
                                symbol: symbol.current?.value.toUpperCase() || '',
                                amount: amount.current?.value || 0,
                                mintable: mintable || false
                            }

                            const stringValidate = (input_string, banned_array, min_length, max_length, error) => {
                                if (input_string.toString().toUpperCase() === 'FISH') {
                                    setError(error.BANNED)
                                    return false
                                }
                                if (input_string.length < min_length || input_string.length > max_length) {
                                    setError(error.LENGTH)
                                    return false
                                }
                                let lev = 0
                                let lcs = 0
                                let sim = 0
                                for (const banned_name of banned_array) {
                                    let i_lev = similarity(input_string.toString().toUpperCase(), banned_name, 'levenshtein')
                                    if (i_lev > lev) {
                                        lev = i_lev
                                    }
                                    let i_lcs = similarity(input_string.toString().toUpperCase(), banned_name, 'lcs')
                                    if (i_lcs > lcs) {
                                        lcs = i_lcs
                                    }
                                    let i_sim = stringSimilarity.compareTwoStrings(input_string.toString().toUpperCase(), banned_name)
                                    if (i_sim > sim) {
                                        sim = i_sim
                                    }
                                    if (input_string?.length > banned_name.length) {
                                        for (let i = 0; i < (input_string?.length - banned_name.length); i++) {
                                            const slice = input_string.slice(i, i + banned_name.length).toString().toUpperCase()
                                            i_lev = similarity(slice, banned_name, "levenshtein")
                                            if (i_lev > lev) {
                                                lev = i_lev
                                            }
                                            i_lcs = similarity(slice, banned_name, "lcs")
                                            if (i_lcs > lcs) {
                                                lcs = i_lcs
                                            }
                                            i_sim = stringSimilarity.compareTwoStrings(slice, banned_name)
                                            if (i_sim > sim) {
                                                sim = i_sim
                                            }
                                        }


                                    }

                                    if (lcs > 0.7 || lev > 0.7 || sim > 0.7) {
                                        setError(error.BANNED)
                                        return false
                                    }
                                }
                                // console.log('%c lev     ' + lev, 'color: red')
                                // console.log('%c lcs     ' + lcs, 'color: blue')
                                // console.log('%c sim      ' + sim, 'color: purple')

                                return true
                            }

                            const amountValidate = () => {
                                const valid = Number.isInteger(Number(form_data.amount.toString()))
                                    && form_data.amount > 0 && form_data.amount < Math.pow(10, 20)
                                if (valid) {
                                    return true
                                } else {
                                    setError(ERROR_AMOUNT.INVALID)
                                    return false
                                }
                            }

                            if(!socket.connected) {
                                setError('Could not establish connection to the server')
                                return false
                            } else {
                                form.current = {...form_data}
                                setValidForm(stringValidate(form_data.name, banned_token_names, 3, 20, ERROR_NAME) &&
                                    stringValidate(form_data.symbol, banned_symbol_names, 3, 6, ERROR_SYMBOL) &&
                                    amountValidate())
                            }

                            // setValidForm(stringValidate(form_data.name, banned_token_names, 3, 20, ERROR_NAME) &&
                            //     stringValidate(form_data.symbol, banned_symbol_names, 2, 6, ERROR_SYMBOL) &&
                            //     amountValidate())

                            // form.current = {...form_data}
                            // setValidForm(true)




                        }}>Generate Token
                        </div>
                        {error && <div className='error'>{error}</div>}
                    </div>
                    : progress === 'coin' ?
                    <>
                        <div className="coin-container spin" onClick={() => {
                            // setProgress('token')
                            // setTimeout(() => {
                            //     const coin = document.getElementById('coin-sound');
                            //     coin.play()
                            // }, 900)
                            window.open(qrlink, '_blank').focus();
                        }}>
                            <div className="image">
                                {/*<Image src={zeniq} layout={'fill'}/>*/}
                                <QRCode value={qrlink}  bgColor={'transparent'}  size={300}/>
                            </div>
                        </div>
                        <div className="stamp">
                            <div className="image">
                                <Image src={zeniq} layout={'fill'}/>
                            </div>
                        </div>
                        {/*<audio ref={stamp} id={'stamp-sound'} style={{display: 'none'}} controls>*/}
                        {/*    <source src={'../../assets/stamp.mp3'} type="audio/mp3"/>*/}
                        {/*</audio>*/}

                        <audio ref={coin} id={'stamp-sound'} style={{display: 'none'}}>
                            <source src={'../../assets/coin.wav'} type="audio/wav"/>
                        </audio>
                    </> :  <>
                            <div className="shadow"/>
                            <div className="coin-container token" onClick={() => {
                                // setProgress('form')
                                // window.open(qrlink, '_blank').focus();
                            }}>
                                <div className="token-info">
                                    {/*<div className="top">*/}
                                    {/*    <div className="header oxa">ZEN-20</div>*/}
                                    {/*</div>*/}
                                    <div className="name">{form.current.name}</div>
                                    <div onClick={() => {
                                        navigator.clipboard.writeText(contractAddress || 'ERROR');}
                                    }>
                                        <QRCode value={`${form.current.name}/${form.current.symbol}/${contractAddress}/tokengenerator`} size={200}/>
                                    </div>
                                    <div className="contract">{contractAddress}</div>
                                    {/*<div className="symbol">{form.current.symbol}</div>*/}
                                    {/*<div className="amount">{form.current.amount}</div>*/}
                                </div>
                            </div>
                            <audio ref={coin} id={'coin-sound'} style={{display: 'none'}} autoPlay={true}>
                                <source src={'../../assets/coin.wav'} type="audio/wav"/>
                            </audio>
                        </>
            }
            <audio ref={stamp} id={'stamp-sound'} style={{display: 'none'}} controls>
                <source src={'../../assets/stamp.mp3'} type="audio/mp3"/>
            </audio>
            <div className="help" onClick={() => {isMobile && setShowTooltip(!showTooltip)}} onMouseEnter={() => {
                !isMobile && setShowTooltip(true)
            }} onMouseLeave={() => {
                !isMobile && setShowTooltip(false)
            }}>
                <Image src={help} layout={'fill'}/>
                { showTooltip &&
                    <div className="tooltip">
                        { (() => {
                            switch (progress) {
                                case 'form':
                                    return 'Fill out the fields to create your own token on the ZENIQ Smartchain'
                                case 'coin':
                                    return 'Use your Nomo App to scan the QR Code and allow the Tokengenerator to request your wallet address'
                                case 'token':
                                    return 'Add a custom token to your Nomo App by clicking "Add Token" and entering the token address shown below.'
                            }
                        })()}
                    </div>
                }
            </div>

            <div className={"button-container " + (banner ? 'show-banner' : 'hide-banner')} onClick={() => {
                setBanner(false)
            }}>
                <div className='banner'>
                    <div>
                        { (() => {
                            switch (progress) {
                                case 'form':
                                    return 'Fill out the fields to create your own token on the ZENIQ Smartchain'
                                case 'coin':
                                    return 'Use your Nomo App to scan the QR Code and allow the Tokengenerator to request your wallet address'
                                case 'token':
                                    return 'Add a custom token to your Nomo App by clicking "Add Token" and entering the token address shown below.'
                            }
                        })()}
                    </div>
                </div>
                <div className="close" onClick={() => {
                    setBanner(false)}}>
                    <Image src={cross} width={20} height={20}/>
                </div>
            </div>
        </>

    )
}


const Tokengenerator = () => {
    return (
        <div id="tokengenerator">
            <div className="zeniq-logo">
                <Image src={zeniq} layout={'fill'}/>
            </div>
            <Form/>
        </div>
    )
}

export default Tokengenerator