import {NextSeo} from "next-seo";
import Content from "../components/Content/Content";
import Tokengenerator from "../components/Tokengenerator/Tokengenerator";

export default function App() {
    return (
        <>
            <NextSeo
                title="ZENIQ Tokengenerator"
                description="Create your own tokens on the ZENIQ Smartchain"
            />
            <Content>
                <Tokengenerator/>
            </Content>
        </>
    );
}
